import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { setCurrentUser } from "actions/currentUser";
// import { LOGOUT } from "actions/actionType";
import "./IndexNavbar.css";

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseOut, setCollapseOut] = useState("");
  const [color, setColor] = useState("navbar-transparent");

  const user = useSelector((state) => state.currentUserReducer);
  const regexUser = user?.userEmail;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("Profile");
    // dispatch({ type: LOGOUT });
    history.push("/");
    dispatch(setCurrentUser(null));
    window.location.reload();
  };

  useEffect(() => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        handleLogout();
      }
    }
    dispatch(setCurrentUser(JSON.parse(localStorage.getItem("Profile"))));

    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  }, [user?.token, dispatch]);

  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <span>SCS• </span>
            Academics Council
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            SAKHA - Your Friend in Campus
          </UncontrolledTooltip>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="/" onClick={(e) => e.preventDefault()}>
                  SCS•Academics
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.linkedin.com/company/academics-council-iit-bhu/"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on LinkedIn"
              >
                <i className="fab fa-linkedin" />
                <p className="d-lg-none d-xl-none">LinkedIn</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0 d-none d-lg-block">
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/sakha.scs.iitbhu/"
                rel="noopener noreferrer"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fab fa-facebook-square" />
                <p className="d-lg-none d-xl-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/scs.iitbhu/"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fab fa-instagram" />
                <p className="d-lg-none d-xl-none">Instagram</p>
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                href="#pablo"
                nav
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-cogs d-lg-none d-xl-none" />
                Important Links
              </DropdownToggle>
              <DropdownMenu className="dropdown-with-icons">
                <DropdownItem tag={Link} to="/about-us">
                  <i className="tim-icons icon-paper" />
                  About Us
                </DropdownItem>
                <DropdownItem tag={Link} to="/resources">
                  <i className="tim-icons icon-bullet-list-67" />
                  Resources
                </DropdownItem>
                <DropdownItem tag={Link} to="/notes">
                  <i className="tim-icons icon-notes" />
                  Noteflix
                </DropdownItem>
                <DropdownItem tag={Link} to="/contact-us">
                  <i className="tim-icons icon-email-85" />
                  Contact Us
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <Button
                className="nav-link d-none d-lg-block"
                color="warning"
                href="/resources"
              >
                <i className="tim-icons icon-single-copy-04" /> Resources
              </Button>
            </NavItem>
            <NavItem>
              {user === null ? (
                <Button
                  className="nav-link d-lg-block"
                  color="primary"
                  href="/login"
                >
                  &nbsp;
                  <i className="tim-icons icon-spaceship" />
                  Login &nbsp;
                </Button>
              ) : (
                <Button
                  className="nav-link d-lg-block"
                  color="warning"
                  onClick={handleLogout}
                >
                  &nbsp;
                  <i className="tim-icons icon-user-run" />
                  Logout &nbsp;
                </Button>
              )}
            </NavItem>
            <NavItem className="d-none d-lg-block">
              {user == null ? (
                <></>
              ) : (
                <div className="circular-div warning" color="warning">
                  <span>{regexUser?.toUpperCase()[0]}</span>
                </div>
              )}
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
