export const statsCard = [
  [
    {
      id: 1,
      number: "200+",
      category: "Sessions",
      color: "text-warning",
      icon: "icon-bulb-63",
    },
    {
      id: 2,
      number: "9000+",
      category: "Benefitting Students",
      color: "text-white",
      icon: "icon-shape-star",
      upper: "upper bg-default",
    },
  ],
  [
    {
      id: 3,
      number: "120+",
      category: "Volunteers",
      color: "text-info",
      icon: "icon-satisfied",
    },
    {
      id: 4,
      number: "350+",
      category: "Guest Speakers",
      color: "text-success",
      icon: "icon-single-02",
    },
  ],
];
