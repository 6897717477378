import React, { useState, useRef } from "react";
import { slideDown, slideUp } from "./anim";
import "./ExTable.css";

const ExTable = ({ event, idx }) => {
  const [expanded, setExpanded] = useState(false);
  const expanderBodyRef = useRef(null);

  const toggleExpander = (e) => {
    if (e.target.type === "checkbox") return;

    if (!expanded) {
      setExpanded(true);
      if (expanderBodyRef.current) {
        slideDown(expanderBodyRef.current);
      }
    } else {
      slideUp(expanderBodyRef.current, {
        onComplete: () => {
          setExpanded(false);
        },
      });
    }
  };

  return [
    <tr key="main" onClick={toggleExpander}>
      <td className="table-shrink"></td>
      <td className="table-shrink">{idx}.</td>
      {/* <td>
        <img
          className="rounded-circle"
          src={event?.image_link}
          width={48}
          alt="avatar"
        />
      </td> */}
      <td>
        {event?.name}
        <br />
        <small>{event?.slug}</small>
      </td>
      <td>
        {event?.date} &nbsp; {event?.time}
      </td>
      <td>{event?.venue}</td>
    </tr>,
    expanded && (
      <tr className="expandable" key="tr-expander">
        <td className="bg-muted" colSpan={6}>
          <div ref={expanderBodyRef} className="inner row">
            <div className="col-4 text-center">
              <img
                className="responsive"
                // style={{ width: 250, height: 180 }}
                src={event?.image_link}
                alt="Event poster"
              />
            </div>
            <div className="col-8">
              <h3>{event?.name}</h3>
              <p>
                <i>
                  <span className="title">Venue: </span> {event?.venue}
                  <br />
                  <span className="title">Date: </span> {event?.date}
                  <br />
                  <span className="title">Time: </span> {event?.time}
                  <br />
                  <span className="title">Description: </span>{" "}
                  {event?.description}
                  <br />
                  {event?.read_more !== "" ? (
                    <a href={event?.read_more} target="blank">
                      {"Read More"}
                    </a>
                  ) : (
                    ""
                  )}
                </i>
              </p>
            </div>
          </div>
        </td>
      </tr>
    ),
  ];
};

export default ExTable;
