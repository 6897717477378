import React from "react";
import "./PageNotFound.css";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";
import {
  Button,
} from "reactstrap";

const PageNotFound = () => {
  return (
    <>
    <IndexNavbar />
      <section class="page_404 pl-5 pt-5">
        <div class="container">
          <div class="row">
            <div class="col-12 justify-center">
              <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                <h1 className="text-center" style={{ color: 'black' }}>404</h1>
                </div>

                <div class="contant_box_404">
                  <h3 class="h2">Look like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>
                  <Button
                  className="link_404"
                  size="sm"
                  href="/"
                >Go to Home</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PageNotFound;
