import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";

export default function Home() {
  const websiteData = useSelector((state) => state.websiteDataReducer);
  const Home_images = websiteData.vission_images;

  const carouselItems = [
    {
      src:
        Home_images?.[0]?.carousel_link ||
        require("assets/img/carousel1-min.webp"),
      altText: "Slide 1",
      caption: "SAKHA",
    },
    {
      src: Home_images?.[1]?.carousel_link,
      altText: "Slide 2",
      caption: "Academics Council '22-23",
    },
    {
      src: Home_images?.[2]?.carousel_link,
      altText: "Slide 3",
      caption: "",
    },
    {
      src: Home_images?.[3]?.carousel_link,
      altText: "Slide 4",
      caption: "Team bonding session",
    },
  ];

  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("contact-us");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (ps) {
        ps.destroy();
      }
      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("contact-us");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.webp")}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png")}
          />
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                <h1 className="profile-title text-left">Academics</h1>
                <h5 className="text-on-back">SCS</h5>
                <p className="profile-description">
                  We help students perform well academically by providing them
                  assistance through tips, accessible study materials, and
                  guidance from their seniors who have previously studied and
                  excelled in the course, hereby connecting freshers with them
                  early on in their campus journey.
                </p>
                <div className="btn-wrapper profile pt-3">
                  <Button
                    className="btn-icon btn-round"
                    color="linkedIn"
                    href="https://www.linkedin.com/company/academics-council-iit-bhu/"
                    id="tooltip639225725"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip639225725">
                    Follow us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-round"
                    color="instagram"
                    href="https://www.instagram.com/scs.iitbhu/"
                    id="tooltip982846143"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip982846143">
                    Like us
                  </UncontrolledTooltip>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" lg="5" md="6">
                <img
                  alt="..."
                  className="img-fluid rounded height-auto"
                  src={require("assets/img/SCS-Logo-min.webp")}
                  style={{ width: "500px" }}
                />
                <h1
                  className="d-block title text-uppercase mt-1"
                  style={{ fontFamily: "Helvetica, sans-serif" }}
                >
                  We Rise By Helping Others
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section mt-5">
          <Container>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel
                    id="home-carousel"
                    items={carouselItems}
                  />
                </Row>
              </Col>
              <Col md="5">
                <h1 className="profile-title text-left">Vision and Mission</h1>
                <h5 className="text-on-back">OUR</h5>
                <p className="profile-description text-justify">
                  Our Primary Vision is to help students perform well
                  academically by providing them assistance through tips,
                  accessible study materials, and guidance from their seniors
                  who have previously studied and excelled in the course, hereby
                  connecting freshers with them early on in their campus
                  journey. <br />
                  We also organize academic-oriented sessions to help students
                  in their preparation for their Internship, Placement and
                  Research Oriented Activities.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col md="6">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Noteflix</h1>
                    <h5 className="text-on-back" id="noteflix">
                      Notes
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <p className="h4">
                      About Noteflix : Platform where students can get the notes
                      and previous year's papers for their exam preparation.
                    </p>
                    <div>
                      <Button
                        className="btn-round float-left"
                        color="primary"
                        data-placement="right"
                        id="tooltip341148792"
                        type="button"
                        href="/notes"
                      >
                        Go to Noteflix
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4">
                <Card className="card-coin card-plain"></Card>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
