import { FETCH_WEBSITE_DATA } from "actions/actionType";

const websiteDataReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_WEBSITE_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default websiteDataReducer;
