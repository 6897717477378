import axios from "axios";

const API = axios.create({
  baseURL: "https://academics-backend-uop1.onrender.com",
  // baseURL: "http://localhost:5000",
});

const Blog_url =
  "https://script.google.com/macros/s/AKfycbyQM19ITZrFxW_HPyLrqw6y_HO2u-aN5g1JY2vM0vV9ZXXawafaRf_Hz_Et8qWPY3jJ7Q/exec?action=getBlogs";
const Event_url =
  "https://script.google.com/macros/s/AKfycbyQM19ITZrFxW_HPyLrqw6y_HO2u-aN5g1JY2vM0vV9ZXXawafaRf_Hz_Et8qWPY3jJ7Q/exec?action=getEvents";
const Website_content_url =
  "https://script.google.com/macros/s/AKfycbyQM19ITZrFxW_HPyLrqw6y_HO2u-aN5g1JY2vM0vV9ZXXawafaRf_Hz_Et8qWPY3jJ7Q/exec?action=getWebsiteData";

export const fetchEvents = () => {
  return axios.get(Event_url);
};

export const fetchBlogs = () => {
  return axios.get(Blog_url);
};

export const fetchWebsiteData = () => {
  return axios.get(Website_content_url);
};

export const logIn = (authData) => {
  return API.post("/api/user/login", authData);
};

export const GooglelogIn = (authData) => {
  // console.log("hello");
  return API.post("/api/user/glogin", authData);
};
