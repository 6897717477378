import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// core components
import Footer from "components/Footer/Footer.js";
// import { login, glogin } from "actions/auth";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";

export default function Login() {
  const [squares1to6, setSquares1to6] = useState("");
  const [squares7and8, setSquares7and8] = useState("");

  // const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const redirectPath = location.state?.from || "/";

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("Profile"));
    if (user?.token) {
      history.replace("/");
    }

    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, [history]);

  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.07 +
        "deg) rotateX(" +
        posY * -0.07 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
  };

  const GoogleCred = (credentialResponse) => {
    const token = credentialResponse.credential;
    const userEmail = jwtDecode(token).email;
    const regex = /@(itbhu|iitbhu)\.ac\.in$/;
    if (regex.test(userEmail)) {
      localStorage.setItem("Profile", JSON.stringify({ token, userEmail }));
      history.push(redirectPath);
      // dispatch(glogin(credentialResponse, history, redirectPath));
    } else {
      toast.error("Please Login with Institute email");
    }
  };

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.webp")}
                      />
                      <CardTitle tag="h4"> Log In </CardTitle>
                    </CardHeader>

                    <CardFooter className="m-4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <GoogleLogin
                          className=""
                          width={250}
                          theme="filled_blue"
                          onSuccess={GoogleCred}
                          onError={() => {
                            toast.error("Login with Institute Email");
                          }}
                        />
                        <ul
                          className="mt-3 pt-3"
                          style={{ fontWeight: 500, fontSize: "0.9rem" }}
                        >
                          <li>Use your Institute mail ID for logging in.</li>
                        </ul>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square<your_client_id>4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
