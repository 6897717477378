import Footer from "components/Footer/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import moment from "moment";
// reactstrap components
import {
  Col,
  Row,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  // Table,
} from "reactstrap";
import EventsPage from "./EventsPage";
import BlogsTable from "./BlogsTable";

const Resources = () => {
  const events = useSelector((state) => state.eventsReducer);
  const blogs = useSelector((state) => state.blogsReducer);
  const [withIcons, setwithIcons] = useState(1);
  const currentDate = moment();

  // Separate past events based on date
  const pastEvents = events.filter((event) => {
    const eventDate = moment(event.date, "DD-MM-YYYY");
    return eventDate.isBefore(currentDate);
  });

  // Separate upcoming events based on date
  const upcomingEvents = events.filter((event) => {
    if (event?.date === "To Be Announced") {
      return true;
    } else if (event?.date === "Not Decided") return false;
    const eventDate = moment(event?.date, "DD-MM-YYYY");
    if (eventDate.isAfter(currentDate)) {
      return true;
    }
    return false;
  });

  const toggleTabs = (e, index) => {
    e.preventDefault();
    setwithIcons(index);
  };
  return (
    <>
      <IndexNavbar />
      <section className="section section-lg section-coins mt-5 pt-5">
        <Container className="align-items-center">
          <Row>
            <Col md="12" className="mt-4 mb-0 pb-0">
              <hr className="line-info" />
              <h1 className="mb-5">
                All you need <span className="text-info">Bulletin Board</span>
              </h1>
            </Col>
            <Col className="mt-0 pt-0">
              <Nav
                className="nav-pills-primary nav-pills-icons"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: withIcons === 1,
                    })}
                    onClick={(e) => toggleTabs(e, 1)}
                    href="#upcoming-events"
                    style={{ padding: "8px" }}
                  >
                    <i className="tim-icons icon-time-alarm" />
                    Upcoming events
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: withIcons === 2,
                    })}
                    onClick={(e) => toggleTabs(e, 2)}
                    href="#past-events"
                    style={{ padding: "8px" }}
                  >
                    <i className="tim-icons icon-laptop" />
                    Past events
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: withIcons === 3,
                    })}
                    onClick={(e) => toggleTabs(e, 3)}
                    href="#blogs"
                    style={{ padding: "8px" }}
                  >
                    <i className="tim-icons icon-calendar-60" />
                    Blogs/Datasheet
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                className="tab-space"
                activeTab={"withIcons" + withIcons}
              >
                <TabPane tabId="withIcons1">
                  <EventsPage events={upcomingEvents} />
                </TabPane>
                <TabPane tabId="withIcons2">
                  <EventsPage events={pastEvents} />
                </TabPane>
                <TabPane tabId="withIcons3">
                  <BlogsTable data={blogs} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Resources;
