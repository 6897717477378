import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "components/Utils/ProtectedRoute";

import Login from "views/main/Login.js";
import Home from "views/main/Home.js";
import ContactUs from "components/Pages/ContactUs/ContactUs";
import NotesPage from "components/Pages/NotesPage/NotesPage";
import AboutUs from "components/Pages/AboutUs/AboutUs";
import Resources from "components/Pages/Resources/Resources";
import { useDispatch } from "react-redux";
import { getEvents } from "actions/events";
import { getBlogs } from "actions/blogs";
import { getWebsiteData } from "actions/websiteData";
import { ToastContainer } from "react-toastify";

import "./components/Utils/ScrollDown/ScrollDown.css";
import PageNotFound from "components/PageNotFound/PageNotFound";
const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogs());
    dispatch(getEvents());
    dispatch(getWebsiteData());
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        {/* <ScrollDown /> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/resources" component={Resources} />
          <ProtectedRoute path="/notes" component={NotesPage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
      <ToastContainer autoClose={1500} limit={1} />
    </>
  );
};

export default App;
