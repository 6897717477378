import React from "react";
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap";

const CardUtil = ({ stats }) => {
  return (
    <>
      <Card className={`card-stats ${stats?.upper}`}>
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div className="icon-big text-center icon-warning">
                <i className={`tim-icons ${stats.color} ${stats.icon}`} />
              </div>
            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <CardTitle tag="p">{stats.number}</CardTitle>
                <p />
                <p className="card-category">{stats.category}</p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CardUtil;
