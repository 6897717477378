import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalUtil = ({ event }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div>
      <Button
        className="btn-round w-100"
        color={event?.color}
        onClick={toggle}
        size="lg"
      >
        {event?.name}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{event?.name}</ModalHeader>
        <ModalBody>{event?.description}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalUtil;
