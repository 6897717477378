import { combineReducers } from "redux";
import eventsReducer from "./events";
import blogsReducer from "./blogs";
import authReducer from "./auth";
import currentUserReducer from "./currentUser";
import websiteDataReducer from "./websiteData";

export default combineReducers({
  blogsReducer,
  eventsReducer,
  websiteDataReducer,
  authReducer,
  currentUserReducer,
});
