import * as api from "../api";
import { FETCH_WEBSITE_DATA } from "./actionType";

// Action Creators

export const getWebsiteData = () => async (dispatch) => {
  try {
    const { data } = await api.fetchWebsiteData();
    const action = { type: FETCH_WEBSITE_DATA, payload: data };
    dispatch(action);
  } catch (error) {
    // console.log(error.message);
  }
};
