import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import "../../Utils/ScrollDown/ScrollDown.css";
import "./ContactUs.css";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";
import emailjs from "@emailjs/browser";

export default function ContactUs() {
  const websiteData = useSelector((state) => state.websiteDataReducer);
  const [, setUserName] = useState("");
  const [, setUserEmail] = useState("");
  const [, setUserPhone] = useState("");
  const [, setUserRoll] = useState("");
  const [, setUserMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    var params = {
      user_name: document.getElementById("username").value,
      user_email: document.getElementById("useremail").value,
      user_phone: document.getElementById("userphone").value,
      user_roll: document.getElementById("userroll").value,
      user_message: document.getElementById("usermessage").value,
      to_name: "Academics Team",
    };

    // console.log(params);
    e.target.reset();
    if (
      params.user_email &&
      params.user_email &&
      params.user_phone &&
      params.user_roll &&
      params.user_message
    ) {
      emailjs
        .send(
          "service_zujtd9t",
          "template_axqzeud",
          params,
          "kApUIatoAR1Q65v8O"
        )
        .then(
          (result) => {
            // console.log(result.text);
            toast.success("Message was sent Successfully");
          },
          (error) => {
            // console.log(error.text);
            toast.error("Error occurred. Please Retry.");
          }
        );
    } else {
      toast.error("Please Enter All Values");
    }
  };

  const [tabs, setTabs] = React.useState(1);

  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("contact-us");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (ps) {
        ps.destroy();
      }
      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("contact-us");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.webp")}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png")}
          />
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                <h1 className="profile-title text-left">Academics Council</h1>
                <h5 className="text-on-back">01</h5>
                <p className="profile-description">
                  We organize academic-oriented sessions to help students in
                  their preparation for their Internship or Placement or
                  Research Oriented Activities.
                  <br />
                  We encourage students to pursue Research in their respective
                  fields by conducting sessions/Workshops of various students’
                  need.
                  <br />
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="6" md="7">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <h4 className="title">Let's meet the Team</h4>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-tabs-primary justify-content-center"
                      tabs
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 1,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(1);
                          }}
                          href="#faculty"
                        >
                          Faculty
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 2,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(2);
                          }}
                          href="#head"
                        >
                          Head
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 3,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(3);
                          }}
                          href="#coHead"
                        >
                          Co-head
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 4,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(4);
                          }}
                          href="#coHead"
                        >
                          Co-head
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-subcategories"
                      activeTab={"tab" + tabs}
                    >
                      <TabPane tabId="tab1">
                        <div className="d-flex justify-content-center align-items-center">
                          {/* First image and content */}
                          <div className="d-flex flex-column align-items-center pr-2">
                            <img
                              alt="profile_pic"
                              className="img-center img-fluid rounded-circle"
                              src={websiteData?.faculty?.[0]?.image}
                            />
                            <h4 className="title mt-2 mb-1">
                              {websiteData?.faculty?.[0]?.name}
                            </h4>
                            <h5 className="title p-0 m-0">
                              {websiteData?.faculty?.[0]?.department}
                            </h5>
                          </div>

                          {/* Second image and content */}
                          <div className="d-flex flex-column align-items-center pl-1">
                            <img
                              alt="profile_pic"
                              className="img-center img-fluid rounded-circle"
                              src={websiteData?.faculty?.[1]?.image}
                            />
                            <h4 className="title mt-2 mb-1">
                              {websiteData?.faculty?.[1]?.name}
                            </h4>
                            <h5 className="title p-0 m-0">
                              {websiteData?.faculty?.[1]?.department}
                            </h5>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="tab2">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex flex-column align-items-center pl-1">
                            <img
                              alt="profile_pic"
                              className="img-center img-fluid rounded-circle"
                              src={websiteData?.head?.[0]?.image}
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center pl-1">
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>Name: </span>{" "}
                              {websiteData?.head?.[0]?.name}
                            </h4>
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>
                                Programme:{" "}
                              </span>
                              {websiteData?.head?.[0]?.department}
                            </h4>
                            <h5 className="title pl-1 mt-0">
                              <span style={{ color: "#e14eca" }}>
                                {" "}
                                Email-Id:{" "}
                              </span>{" "}
                              {websiteData?.head?.[0]?.email}
                            </h5>
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>
                                {" "}
                                Connect with me:{" "}
                              </span>{" "}
                              <a
                                href={websiteData?.head?.[0]?.linkedin}
                                title="LinkedIn"
                                class="btn btn-linkedin btn-lg"
                              >
                                <i className="fab fa-linkedin fa-fw"></i>{" "}
                                LinkedIn
                              </a>
                            </h4>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="tab3">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex flex-column align-items-center pl-1">
                            <img
                              alt="profile_pic"
                              className="img-center img-fluid rounded-circle"
                              src={websiteData?.head?.[1]?.image}
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center pl-1">
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>Name: </span>{" "}
                              {websiteData?.head?.[1]?.name}
                            </h4>
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>
                                Programme:{" "}
                              </span>
                              {websiteData?.head?.[1]?.department}
                            </h4>
                            <h5 className="title pl-1 mt-0">
                              <span style={{ color: "#e14eca" }}>
                                {" "}
                                Email-Id:{" "}
                              </span>{" "}
                              {websiteData?.head?.[1]?.email}
                            </h5>
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>
                                {" "}
                                Connect with me:{" "}
                              </span>{" "}
                              <a
                                href={websiteData?.head?.[1]?.linkedin}
                                title="LinkedIn"
                                class="btn btn-linkedin btn-lg"
                              >
                                <i className="fab fa-linkedin fa-fw"></i>{" "}
                                LinkedIn
                              </a>
                            </h4>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="tab4">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex flex-column align-items-center pl-1">
                            <img
                              alt="profile_pic"
                              className="img-center img-fluid rounded-circle"
                              src={websiteData?.head?.[2]?.image}
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center pl-1">
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>Name: </span>{" "}
                              {websiteData?.head?.[2]?.name}
                            </h4>
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>
                                Programme:{" "}
                              </span>
                              {websiteData?.head?.[2]?.department}
                            </h4>
                            <h5 className="title pl-1 mt-0">
                              <span style={{ color: "#e14eca" }}>
                                {" "}
                                Email-Id:{" "}
                              </span>{" "}
                              {websiteData?.head?.[2]?.email}
                            </h5>
                            <h4 className="title mt-0">
                              <span style={{ color: "#e14eca" }}>
                                {" "}
                                Connect with me:{" "}
                              </span>{" "}
                              <a
                                href={websiteData?.head?.[2]?.linkedin}
                                title="LinkedIn"
                                class="btn btn-linkedin btn-lg"
                              >
                                <i className="fab fa-linkedin fa-fw"></i>{" "}
                                LinkedIn
                              </a>
                            </h4>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col md="6">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Contact-Us</h1>
                    <h5 className="text-on-back">02</h5>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={sendEmail}>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Your Name</label>
                            <Input
                              id="username"
                              onChange={(e) => {
                                setUserName(e.target.value);
                              }}
                              placeholder="Enter your name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>College Email address</label>
                            <Input
                              id="useremail"
                              onChange={(e) => {
                                setUserEmail(e.target.value);
                              }}
                              placeholder="abc@email.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Phone</label>
                            <Input
                              id="userphone"
                              onChange={(e) => {
                                setUserPhone(e.target.value);
                              }}
                              placeholder="123456789"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>RollNo</label>
                            <Input
                              id="userroll"
                              onChange={(e) => {
                                setUserRoll(e.target.value);
                              }}
                              placeholder="20075200"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Message</label>
                            <Input
                              id="usermessage"
                              placeholder="Enter your query here!"
                              type="text"
                              onChange={(e) => {
                                setUserMessage(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        className="btn-round float-right"
                        color="primary"
                        data-placement="right"
                        id="tooltip341148792"
                        type="submit"
                      >
                        Send text
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target="tooltip341148792"
                      >
                        Can't wait for your message
                      </UncontrolledTooltip>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Find us at the office</h4>
                    <p>
                      SCS Office, Third floor Common hall
                      <br />
                      DG-2 Hostel <br />
                      IIT(BHU), Varanasi
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-email-85" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Email us at</h4>
                    <p>
                      academics.sakha@iitbhu.ac.in <br />
                      sakha.scs@iitbhu.ac.in
                      <br />
                      24*7 everyday support
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
