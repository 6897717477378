import ExTable from "components/Utils/ExpandableTable/ExTable";
import React from "react";
import { Table } from "reactstrap";

const EventsPage = ({ events }) => {
  const isLoading = events === null;
  const headings = ["Event Name", "Date and Time", "Venue"];
  return (
    <>
      <main>
        <div>
          <Table className="table table-hover table-middle table-divider">
            <thead>
              <tr>
                <th className="table-shrink" />
                <th className="table-shrink" />
                {/* <th className="table-shrink">Avatar</th> */}
                {headings.map((h, i) => (
                  <th key={i} style={{ color: "#e14eca" }}>
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <em className="text-muted">Loading...</em>
                  </td>
                </tr>
              ) : (
                events.map((event, idx) => (
                  <ExTable key={idx} idx={idx + 1} event={event} />
                ))
              )}
            </tbody>
          </Table>
        </div>
      </main>
    </>
  );
};

export default EventsPage;
