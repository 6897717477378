import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss";
import "assets/demo/demo.css";

import Reducers from "./reducers";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = createStore(Reducers, compose(applyMiddleware(thunk)));

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="140045945699-sga7u5blul51ub0f47s01kqcju5je4uh.apps.googleusercontent.com">
      {" "}
      <App />
    </GoogleOAuthProvider>
  </Provider>
);
