import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem("Profile"));
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user?.token) return <Component {...props} />;
        else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: location.pathname } }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
