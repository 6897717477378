import * as api from "../api";
import { FETCH_EVENTS } from "./actionType";

// Action Creators

export const getEvents = () => async (dispatch) => {
  try {
    const { data } = await api.fetchEvents();
    const action = { type: FETCH_EVENTS, payload: data };
    dispatch(action);
  } catch (error) {
    // console.log(error.message);
  }
};
