import SearchBar from "components/NotesTable/SearchBar";
import NotesTable from "components/NotesTable/NotesTable";
import "./NotesPage.css";
import axios from "axios";
import img from "../../../assets/img/SCS-Logo-min.webp";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const headings = ["Name", "Code", "Course", "Type", "Link"];

export default function NotesPage() {
  const [data, setData] = useState(() => []);
  const [visibleData, setVisibleData] = useState(() => []);
  const [searchText, setSearchText] = useState(() => "");

  const filterData = () => {
    if (searchText === "") {
      setVisibleData(data);
    } else {
      setVisibleData(
        data.filter(
          (each) =>
            each.name.toUpperCase().includes(searchText) ||
            each.code.toUpperCase().includes(searchText) ||
            each.course.toUpperCase().includes(searchText) ||
            each.type.toUpperCase().includes(searchText)
        )
      );
    }
  };

  const fetchData = async () => {
    if (data.length != 0) return;
    await axios
      .get(`https://college-cup.vercel.app/docs/getAll`, {})
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => console.dir("error occured catched", e));
  };

  useEffect(() => {
    fetchData();
    filterData();
  }, [searchText, data]);

  const history = useHistory();
  const navigateHome = () => {
    // 👇️ navigate to /
    history.push("/");
  };

  return (
    <div className="container-xxl">
      {/* <IndexNavbar /> */}
      <header>
        <div
          role="button"
          onClick={navigateHome}
          className="d-flex align-items-center"
        >
          <img
            src={img}
            alt="SCS-logo"
            className="img-fluid"
            style={{ width: "70px" }}
          />{" "}
          <span
            className="fs-6 fst-italic primary text-wrap"
            style={{ width: "8rem" }}
          >
            {" "}
            <span className="title fw-bold">SAKHA: Your friend in campus </span>
          </span>
        </div>
        <h3 className="p-0 m-0 fs-3"> Notes </h3>

        <SearchBar setSearchText={setSearchText} />
      </header>
      <NotesTable headings={headings} data={visibleData} />
    </div>
  );
}
