import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import './ScrollDown.css';


const ScrollArrow = () =>{

  const [showScroll, setShowScroll] = useState(true)

  const checkScrollDown = () => {
    if (showScroll && window.pageYOffset > 10){
      setShowScroll(false)
    } else if (showScroll && window.pageYOffset <= 10){
      setShowScroll(false)
    }
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };
  window.addEventListener('scroll', checkScrollDown)

  return (
        <FaArrowCircleUp className="scrollDown fa-rotate-180" onClick={scrollDown} style={{height: 40, display: showScroll ? 'flex' : 'none'}}/>
  );
}

export default ScrollArrow;