import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  UncontrolledCarousel,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import "../../Utils/ScrollDown/ScrollDown.css";
import ScrollDown from "../../Utils/ScrollDown/ScrollDown";

import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";
import ModalUtil from "components/Utils/Modals/ModalUtil";
import { statsCard } from "components/Utils/Cards/statsCard";
import CardUtil from "components/Utils/Cards/CardUtil";

export default function AboutUs() {
  const websiteData = useSelector((state) => state.websiteDataReducer);
  const events = websiteData.aboutUs_events;
  const carouselItems = [
    {
      src: events
        ? events?.[0]?.image_link
        : require("assets/img/event1-min.webp"),
      altText: "Slide 1",
      caption: "",
    },
    {
      src: events?.[1]?.image_link,
      altText: "Slide 2",
      caption: "",
    },
    {
      src: events?.[2]?.image_link,
      altText: "Slide 3",
      caption: "",
    },
    {
      src: events?.[3]?.image_link,
      altText: "Slide 4",
      caption: "",
    },
    {
      src: events?.[4]?.image_link,
      altText: "Slide 5",
      caption: "",
    },
  ];

  useEffect(() => {
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);
  return (
    <>
      <ScrollDown />
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png")}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png")}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png")}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png")}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png")}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png")}
          />
          <div className="content-center p-0 m-0">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1
                  className="text-white"
                  style={{ fontFamily: "Georgia, Arial, sans-serif" }}
                >
                  Your Friend in Campus
                  <br />
                  <span className="text-white">SAKHA</span>
                </h1>
                <p className="text-white mb-3">
                  SAKHA, an acronym for Seek, Aspire, Know Holistic Advancement,
                  is an Institute faculty-student body that was founded on 2nd
                  April 2019 by Dr PK Jain, Director, Indian Institute of
                  Technology (Banaras Hindu University), Varanasi with the
                  vision to foster the personal growth and overall development
                  of students in the institute by providing them with the right
                  guidance in academics, skills, career and life in general.
                </p>
                <div className="btn-wrapper mb-3">
                  <Button
                    className="btn-link m-0 p-0"
                    color="success"
                    href="https://www.linkedin.com/posts/scs-iitbhu_sakha-your-friend-in-campus-activity-7036704110322069504-GJKP?utm_source=share&utm_medium=member_desktop"
                    // onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    <p className="category text-success d-inline">Read More</p>
                    <i className="tim-icons icon-minimal-right m-1" />
                  </Button>
                </div>
              </Col>
              <Col lg="5" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/SCS-Logo-min.webp")}
                />
              </Col>
            </Row>
          </div>
        </div>
        <section className="section section-lg m-0 p-0">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">
                  {statsCard.map((row) => (
                    <Row key={row[0].id}>
                      {row.map((stats) => (
                        <Col className="px-2 py-2" lg="6" sm="12">
                          <CardUtil stats={stats} key={statsCard?.id} />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1 style={{ fontFamily: "Georgia, Arial, sans-serif" }}>
                      Large <br />
                      Achievements
                    </h1>
                    <p>
                      Since its establishment, the Student Counselling Services
                      has been a SAKHA to students, beginning from their first
                      day on campus during the Induction Program.
                    </p>
                    <br />
                    <p>
                      Followed by Remedial classes, Skill bootcamps, Research
                      workshops, Internship and Placement guidance sessions,
                      Book reading and countless others.
                    </p>
                    <br />
                    {/* <a
                      className="font-weight-bold text-info mt-5"
                      href="https://www.linkedin.com/company/scs-iitbhu/mycompany/"
                      onClick={(e) => e.preventDefault()}
                    >
                      Show all{" "}
                      <i className="tim-icons icon-minimal-right text-info" />
                    </a> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="section section-lg mt-0">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png")}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path5.png")}
          />
          <img
            alt="..."
            className="path3"
            src={require("assets/img/path2.png")}
          />
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h1
                  className="text-center pb-5 mb-4"
                  style={{ fontFamily: "Georgia, Arial, sans-serif" }}
                >
                  Our Domains
                </h1>
                <Row className="row-grid justify-content-center">
                  <Col lg="3">
                    <div className="info p-0">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-puzzle-10" />
                      </div>
                      <h4 className="info-title">Notes and resources</h4>
                      <hr className="line-primary" />
                      <p>
                        It covers maintaining noteflix, Database of Open
                        Elective, HULM and minor course.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info p-0">
                      <div className="icon icon-warning">
                        <i className="tim-icons icon-sound-wave" />
                      </div>
                      <h4 className="info-title">Events and Workshops</h4>
                      <hr className="line-warning" />
                      <p>
                        It covers Remedial Classes, session on Discipline
                        Change, Competitive exams, Acing exams.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info p-0">
                      <div className="icon icon-success">
                        <i className="tim-icons icon-refresh-02" />
                      </div>
                      <h4 className="info-title">Core and Research</h4>
                      <hr className="line-success" />
                      <p>
                        It covers blogs for softwares required in core job
                        profiles, report and thesis writing.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg section-safe">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path5.png")}
          />
          <Container>
            <Row className="row-grid justify-content-between">
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("assets/img/carousel1-min.webp")}
                />
                <Card className="card-stats bg-danger">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">24*7</CardTitle>
                        <p className="card-category text-white">Availability</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-info mt-4">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">9000+</CardTitle>
                        <p className="card-category text-white">
                          Benefitting students
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-default">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">Your</CardTitle>
                        <p className="card-category text-white">Friend</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3 style={{ fontFamily: "Georgia, Arial, sans-serif" }}>
                    Our Structure
                  </h3>
                  <p>
                    The Academics Council consists of{" "}
                    {websiteData?.number_of_head} heads with a team of{" "}
                    {websiteData?.number_of_sg} Student Guides(SGs) led by{" "}
                    {websiteData?.number_of_faculty} Faculty coordinators.
                    <br />
                    Each head leads a domain for Noteflix, Competitive exams,
                    blogs and Research related sessions respectively.
                  </p>
                  <ul className="list-unstyled mt-3">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-vector" />
                        </div>
                        <div className="ml-3">
                          <h6>
                            {websiteData?.number_of_faculty} Faculty
                            Coordinators
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-tap-02" />
                        </div>
                        <div className="ml-3">
                          <h6>{websiteData?.number_of_head} Heads</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6>{websiteData?.number_of_sg} Student Guides</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="section">
          <Container>
            <Row className="justify-content-between">
              <Col md="5">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel
                    items={carouselItems}
                    captionText={null}
                    style={{ color: "red" }}
                  />
                </Row>
              </Col>
              <Col md="6">
                <h1
                  className="profile-title text-left"
                  style={{ fontFamily: "Georgia, Arial, sans-serif" }}
                >
                  Events
                </h1>
                {events
                  ? events.map((event) => (
                      <ModalUtil event={event} key={event?.id} />
                    ))
                  : ""}
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
