import { Table } from "reactstrap";

function NotesTable({ headings, data }) {
  const isLoading = data === null;
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headings.map((h, i) => (
            <th key={i} className="text-center">
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={6} className="text-center">
              <em className="text-muted">Loading...</em>
            </td>
          </tr>
        ) : (
          data.map((each, idx) => {
            return (
              <tr key={idx}>
                <td className="text-center">{each.name}</td>
                <td className="text-center">{each.code}</td>
                <td className="text-center">{each.course}</td>
                <td className="text-center">{each.type}</td>
                <td>
                  <a href={each.url} target="blank">
                    {"Open"}
                  </a>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
}

export default NotesTable;
