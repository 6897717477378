import React from "react";
import { Table } from "reactstrap";

const BlogsTable = ({ data }) => {
  const isLoading = data === null;
  const headings = ["Id", "Name", "Description", "Link"];
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headings.map((h, i) => (
            <th
              key={i}
              className="text-center"
              style={{ border: "1px solid #ffffff1a", color: "#e14eca" }}
            >
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={6} className="text-center">
              <em className="text-muted">Loading...</em>
            </td>
          </tr>
        ) : (
          data.map((blog, idx) => {
            return (
              <tr key={idx}>
                <td className="text-center">{blog?.id}</td>
                <td className="text-center">{blog?.name}</td>
                <td className="text-center">{blog?.description}</td>
                <td>
                  <a href={blog?.link} target="blank">
                    {"Open"}
                  </a>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export default BlogsTable;
